<template>
  <principal-component />
</template>

<script>
import Principal from "@/components/Principal.vue";
export default {
  name: "Home",
  components: { "principal-component": Principal },
};
</script>
