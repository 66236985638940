<template>
  <div>
    <v-responsive>
      <ValidationObserver ref="obs">
        <section id="principal">
          <div class="app-bar-container ">
            <v-app-bar color="transparent elevation-0" tile>
              <v-avatar class="ml-10" size="72px" tile>
                <v-img src="../assets/Logo_pilsa_1.png" alt="Logo Pilsa" contain />
              </v-avatar>

              <v-spacer></v-spacer>
              <!-- 
              <v-btn text class="text-capitalize hidden-sm-and-down">
                ¿Cómo funciona?
              </v-btn>
              <v-btn
                text
                class="text-capitalize hidden-sm-and-down"
                @click="HandlerRedirect()"
              >
                Precios
              </v-btn>
              <v-app-bar-nav-icon
                @click="drawer = !drawer"
                class="d-flex d-sm-flex d-md-none"
              ></v-app-bar-nav-icon> -->
            </v-app-bar>
          </div>
          <section class="main-container">
            <div class="main-container-rigth">
              <div class="main-container-rigth__container-blank">
                <!-- <v-row justify="center" class="mt-md-15">
                  <v-col cols="12" md="5">
                    <p class="text-center" style="font-size: 30px">
                      Déjanos
                      <span class="font-weight-bold">tus comentarios</span>
                    </p>
                  </v-col>
                </v-row> -->
                <div>
                  <label for="" class="ml-1 labelRepeated">
                    Nombre Completo
                  </label>
                  <ValidationProvider
                    name="nombre"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      placeholder="Nombre Completo"
                      v-model="name"
                      outlined
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
                <div>
                  <label for="" class="ml-1 labelRepeated">
                    Nombre de la Empresa
                  </label>
                  <ValidationProvider
                    name="nombre de empresa"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      placeholder="Nombre de Empresa"
                      v-model="name_company"
                      outlined
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
                <div>
                  <label for="" class="ml-1 labelRepeated">
                    Rol que desempeñas en la empresa
                  </label>
                  <ValidationProvider
                    name="compañia rol"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      placeholder="Rol que desempeñas en la empresa"
                      v-model="rol_company"
                      outlined
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
                <div>
                  <label for="" class="ml-1 labelRepeated">
                    Correo electrónico
                  </label>
                  <ValidationProvider
                    name="correo"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      placeholder="Correo electrónico"
                      v-model="email"
                      outlined
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </div>

                <section class="containerEmailNumber">
                  <div class="container-email">
                    <label for="" class="ml-1 labelRepeated">
                      País
                    </label>
                    <ValidationProvider
                      name="pais"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        :items="countries"
                        :item-text="(item) => item.name + ' ' + item.phone_code"
                        :item-value="(item) => item"
                        v-model="country_id"
                        outlined
                        placeholder="País"
                        :error-messages="errors"
                      >
                        <template v-slot:[`selection`]="{ item }">
                          <v-avatar class="px-0 mr-0" size="20">
                            <v-img :src="item.flag"></v-img>
                          </v-avatar>
                          <span>{{ item.phone_code }}</span>
                        </template>
                        <template v-slot:[`item`]="{ item }">
                          <v-avatar class="mr-2" size="20">
                            <v-img :src="item.flag"></v-img>
                          </v-avatar>
                          <span>{{ item.name }}</span>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </div>
                  <div class="container-number">
                    <label for="" class="ml-1 labelRepeated">
                      Teléfono (Whatsapp)
                    </label>
                    <ValidationProvider
                      name="telefono"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="phone_number"
                        outlined
                        placeholder="Teléfono (Whatsapp)"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </section>

                <!-- <div>
                  <label for="" class="ml-3 labelRepeated"> Pais </label>
                  <ValidationProvider
                    name="pais"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="(item) => item.name + ' ' + item.phone_code"
                      :item-value="(item) => item"
                      v-model="country_id"
                      outlined
                      :error-messages="errors"
                    >
                      <template v-slot:[`selection`]="{ item }">
                        <v-avatar class="px-0 mr-0" size="20">
                          <v-img :src="item.flag"></v-img>
                        </v-avatar>
                        <span>{{ item.phone_code }}</span>
                      </template>
                      <template v-slot:[`item`]="{ item }">
                        <v-avatar class="mr-2" size="20">
                          <v-img :src="item.flag"></v-img>
                        </v-avatar>
                        <span>{{ item.name }}</span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </div> -->

                <div>
                  <label for="" class="ml-1 labelRepeated">
                    Cuéntanos tú necesidad
                  </label>
                  <ValidationProvider
                    name="necesidad"
                    rules="required|min:10"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      placeholder="Cuéntanos tú necesidad"
                      v-model="content"
                      outlined
                      :error-messages="errors"
                    ></v-textarea>
                  </ValidationProvider>
                </div>

                <span class="text-adv">
                  Tu privacidad es importante para nosotros. Pilsa utiliza la
                  información que proporcionas para ponerse en contacto contigo
                  en relación con contenido, productos y servicios relevantes
                  para ti. Si deseas obtener más información sobre la protección
                  de tus datos en Pilsa, consulta nuestra Política de
                  Privacidad.
                </span>

                <v-btn
                  :loading="loading"
                  @click="HandlerSaveForm"
                  rounded
                  block
                  color="#101820"
                  class="btn-send"
                  >Enviar</v-btn
                >
                <!-- <v-row justify="center">
                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col cols="12" md="6">

                      </v-col>
                      <v-col cols="12" md="6">

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" class="mt-md-n5">

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">

                      </v-col>
                      <v-col cols="6" md="2">

                      </v-col>
                      <v-col cols="6" md="4">

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" class="mt-md-n5">

                      </v-col>
                    </v-row>
                    <v-row justify="center">

                    </v-row>
                  </v-col>
                </v-row> -->
              </div>
              <!-- <v-row>
                <v-col cols="12" sm="12" md="6" class="mt-md-15">
                  <v-card class="mx-auto card-radius">
                    <v-img
                      src="../assets/img/Imagen.png"
                      alt="imagen principal"
                      dark
                    >
                    </v-img>
                  </v-card>
                </v-col>
              </v-row> -->
            </div>
            <div class="main-container-left">
              <div class="div-container-title">
                <span class="titleGetADemo">
                  Accede a una demostración
                </span>
                <span class="titleGetADemo">
                  gratuita de Pilsa
                </span>
              </div>
              <span class="span-disver-pilsar"
                >Explora Pilsa sin compromiso con una demostración gratuita y
                descubre como mantener capacitados a tu talento
                empresarial.</span
              >
              <ul class="list-details">
                <li>
                  <v-icon class="bg_gradient_icon mr-3">mdi-check</v-icon>
                  <span class="li-detail">Acceso en cualquier lugar</span>
                </li>
                <li>
                  <v-icon class="bg_gradient_icon mr-3">mdi-check</v-icon>
                  <span class="li-detail"
                    >Acceso 24/7 a todos los contenidos</span
                  >
                </li>
                <li>
                  <v-icon class="bg_gradient_icon mr-3">mdi-check</v-icon>
                  <span class="li-detail">Acompañamiento personalizado</span>
                </li>
                <li>
                  <v-icon class="bg_gradient_icon mr-3">mdi-check</v-icon>
                  <span class="li-detail">Seguimiento del progreso</span>
                </li>
                <li>
                  <v-icon class="bg_gradient_icon mr-3">mdi-check</v-icon>
                  <span class="li-detail">Soporte especializado</span>
                </li>
                <li>
                  <v-icon class="bg_gradient_icon mr-3">mdi-check</v-icon>
                  <span class="li-detail">Descuento garantizado</span>
                </li>
              </ul>
              <div class="container-bg">
                <img src="../assets/img/bg_principal.svg" />
              </div>
            </div>
          </section>
        </section>
        <section id="footer" class="footer">
          <div class="footer-container-one">
            <v-img
              src="../assets/Logo_pilsa_1.png"
              alt="Logo Pilsa"
              style="width: 160px;"
            />
            <span class="span-contact-ifyouneed"
              >Contáctanos si necesitas ayuda para tomar una buena decisión en
              cuanto a los planes.</span
            >
            <div class="container-btn-cm">
              <v-btn
                @click="handlerSocialNetwork('facebook')"
                class="mt-2 mt-md-0 elevation-0"
              >
                <v-icon size="28px">mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                @click="handlerSocialNetwork('instagram')"
                class="mt-2 mt-md-0 elevation-0"
              >
                <v-icon size="30px">
                  mdi-instagram
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="footer-container-two">
            <span class="enlaceTitle"> Enlaces</span>
            <button class="enlaceTitle-btn">
              Nosotros
            </button>
            <button
              class="enlaceTitle-btn"
              @click.prevent="HandlerShowConditions"
            >
              Términos y condiciones
            </button>
            <button class="enlaceTitle-btn">Política de Privacidad</button>
          </div>
          <div class="footer-container-three">
            <div class="footer-container-three--c1">
              <span class="footer-container-three_title">Contáctanos</span>
              <span class="footer-container-three_text">
                Contáctanos si necesitas ayuda para tomar una buena decisión en
                cuanto a los planes.
              </span>
            </div>
            <button>
              <div class="footer-container-three--c2" @click="redirectToWsp">
                <span class="footer-container-three_title-2"
                  >Atención Personalizada
                </span>
                <v-icon>mdi-arrow-right</v-icon>
              </div>
            </button>
          </div>
          <!-- <v-footer padless color="transparent">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="px-10 d-flex justify-space-between">
                    <div class="d-flex justify-center" small>
                      <v-btn
                        @click="handlerSocialNetwork('facebook')"
                        icon
                        class="mx-2 mt-2 mt-md-0 elevation-0"
                      >
                        <v-img
                          class="mt-1"
                          contain
                          max-width="40px"
                          src="@/assets/img/facebook.svg"
                        ></v-img>
                      </v-btn>
                      <v-btn
                        @click="handlerSocialNetwork('youtube')"
                        color="#061F42"
                        small
                        fab
                        class="mx-2 mt-2 mt-md-0 elevation-0"
                      >
                        <v-icon color="#e1e1e1" size="28px">
                          mdi-youtube
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="handlerSocialNetwork('whatsapp')"
                        color="#061F42"
                        small
                        fab
                        class="mx-2 mt-2 mt-md-0 elevation-0"
                      >
                        <v-icon color="#e1e1e1" size="28px">
                          mdi-whatsapp
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="handlerSocialNetwork('instagram')"
                        color="#061F42"
                        small
                        fab
                        class="mx-2 mt-2 mt-md-0 elevation-0"
                      >
                        <v-icon color="#e1e1e1" size="28px">
                          mdi-instagram
                        </v-icon>
                      </v-btn>
                    </div>
                    <a href="#" @click.prevent="HandlerShowConditions"
                      >Política de privacidad. Términos y condiciones
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-footer> -->
        </section>
        <v-divider />
        <section class="footer-2">
          <!-- <div class="container-btn-cm2">
            <v-btn
              @click="handlerSocialNetwork('facebook')"
              class="mt-2 mt-md-0 elevation-0"
            >
              <v-icon color="rgba(0,0,0,.54)
              +" size="28px">mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              @click="handlerSocialNetwork('instagram')"
              class="mt-2 mt-md-0 elevation-0"
            >
              <v-icon color="rgba(0,0,0,.54)" size="28px">
                mdi-instagram
              </v-icon>
            </v-btn>
          </div> -->
          <span>Copyright © Pilsa 2023 - Todos los derechos reservados.</span>
        </section>
      </ValidationObserver>
    </v-responsive>
    <v-navigation-drawer
      v-model="drawer"
      bottom
      color="transparent"
      fixed
      height="auto"
      overlay-color="secondary"
      overlay-opacity=".6"
      temporary
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-list color="white" shaped>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-content>
              <v-btn
                color="#2696A6"
                text
                :href="item.href"
                class="font-medium text-capitalize"
                >{{ item.text }}</v-btn
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { api } from "@/services";
import Swal from "sweetalert2";
export default {
  data: () => ({
    loading: false,
    countries: [],
    drawer: false,
    group: null,
    name: "",
    rol_company: "",
    email: "",
    country_id: {},
    country_usa: {},
    phone_number: "",
    content: "",
    name_company: "",
    items: [
      { text: "¿Cómo funciona?", href: "#home" },
      { text: "Precios", href: "#service" },
    ],
    icons: ["mdi-facebook", "mdi-twitter", "mdi-instagram"],
  }),
  created() {
    this.HandlerGetCountries();
  },
  methods: {
    async HandlerGetCountries() {
      try {
        const response = await api.get("/api/v1/country");
        this.country_id = response.data.data.find((val) => val.id == 238);
        this.countries = response.data.data;
        this.country_usa = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    clean() {
      this.name = "";
      this.rol_company = "";
      this.email = "";
      this.name_company = "";
      // this.country_id = this.country_usa;
      this.phone_number = "";
      this.content = "";
      this.$refs.obs.reset();
    },

    async HandlerSaveForm() {
      try {
        this.loading = true;
        const request = {
          name: this.name,
          name_company: this.name_company,
          rol_company: this.rol_company,
          email: this.email,
          country_id: this.country_id.id,
          phone_number: this.phone_number,
          content: this.content,
        };
        await api.post("/api/v1/comment", request);
        this.$snotify.success(
          "Tu mensaje ha sido enviado con exitos",
          "¡Genial!"
        );
        this.clean();
      } catch (error) {
        let e = error.response.data.error;
        if (error.response.status == 422) {
          let list = "";

          for (const prop in e) {
            list = list + `<li>${e[prop][0]}</li>`;
          }

          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: list,
          });
        } else {
          this.$snotify.error(
            "Ha ocurrido un error, porfavor intenta mas tarde",
            "Error!"
          );
        }
        // }
      } finally {
        this.loading = false;
      }
    },

    HandlerRedirect() {
      window.location.href = "https://pilsa.io/#price";
    },
    redirectToWsp() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=12133109260&text&type=phone_number&app_absent=0";
    },

    HandlerShowConditions() {
      window.location = "https://pilsa.io/conditions";
    },

    handlerSocialNetwork(social) {
      if (social == "youtube") {
        window.open(
          "https://www.youtube.com/channel/UCOFfCqO3gg1NC5-3rqpUiow",
          "_blank"
        );
      } else if (social == "facebook") {
        window.open("https://www.facebook.com/PILSA-107456954766217", "_blank");
      } else if (social == "whatsapp") {
        window.open(
          "https://wa.me/+14243914101?text=Hola%20Bienvenidoa%20Pilsa",
          "_blank"
        );
      } else if (social == "instagram") {
        window.open("https://www.instagram.com/pilsala/", "_blank");
      }
    },
  },
};
</script>

<style scoped>
.enlaceTitle-btn {
  text-align: left;
  font: normal normal 300 16px/25px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.footer-container-three--c1 {
  display: flex;
  flex-direction: column;
}
.footer-container-three--c2 {
  display: flex;
  gap: 1rem;
}
.footer-2 {
  text-align: center;
  background: #f9f9f9;
  min-height: 81px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font: normal normal 300 15px/23px Poppins;
  letter-spacing: 0px;
  color: #101820;
  padding-bottom: 1rem;
}
@media (max-width: 1024px) {
  .footer {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .footer-container-one,
  .span-contact-ifyouneed {
    width: 100% !important;
    max-width: none !important;
  }
  .main-container {
    flex-direction: column-reverse !important;
    height: auto !important;
  }
  /* .main-container-left {
    display: none !important;
  } */
  /* .main-container-rigth {
    align-items: center;
  } */
  .main-container-rigth,
  .main-container-left {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .main-container-rigth {
    padding: 46px 14px !important;
  }
  .containerEmailNumber {
    flex-direction: column !important;
    gap: 0 !important;
  }
  .container-email,
  .container-number {
    width: 100% !important;
  }
  .container-btn-cm2 {
    display: flex !important;
  }
}
@media (max-width: 425px) {
  .main-container-left {
    display: none !important;
  }
  .footer {
    padding: 48px !important;
  }
  .footer-container-three_title-2 {
    font-size: 14px !important;
  }
}

.container-bg {
  /* width: 100%; */
  position: absolute;
  bottom: -7px;
  right: 0;
}
.footer-container-three {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: red; */
  max-width: 350px;
  min-height: 212px;
}
.footer-container-three_title-2 {
  text-align: left;
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.footer-container-three_title {
  text-align: left;
  font: normal normal bold 24px/35px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.footer-container-three_text {
  max-width: 383px;
  font: normal normal 300 15px/23px Poppins;
  letter-spacing: 0px;
  color: #101820;
  text-align: left;
  margin: 33px 0;
}
.footer-container-two {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: red; */
  max-width: 300px;
  min-height: 212px;
  /* align-items: center;
  justify-content: center; */
}
.footer-container-two > button {
  text-align: left;
}
.enlaceTitle {
  font: normal normal bold 24px/35px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.span-contact-ifyouneed {
  margin-bottom: 20px;
  max-width: 310px;
  text-align: left;
  font: normal normal 300 15px/23px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.container-btn-cm {
  display: flex;
  /* background-color: red; */
  align-items: flex-start;
}

.container-btn-cm2 {
  display: flex;
  /* background-color: red; */
  display: none;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
.footer-container-one {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.footer {
  background: #f9f9f9;
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  flex-wrap: wrap;
}
.btn-send {
  /* display: block; */
  margin-top: 27px;
  /* margin-top: 1rem; */
  font: normal normal medium 16px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff !important;
  text-transform: capitalize;
  background: #101820 0% 0% no-repeat padding-box;
  border-radius: 32px;
}
.text-adv {
  display: block;
  text-align: left;
  font: normal normal normal 11px/20px "Poppins";
  letter-spacing: 0px;
  color: #1f1922;
}
.container-number {
  width: 100%;
  min-width: 100px;
}
.container-email {
  width: 50%;
  min-width: 100px;
  max-width: 50px;
}
.containerEmailNumber {
  display: flex;
  gap: 1rem;
}
.labelRepeated {
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.main-container-rigth__container-blank {
  box-shadow: 0px 3px 18px #00000014;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 2rem;
}
.li-detail {
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.list-details {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  padding: 0;
}
.bg_gradient_icon {
  /* background: transparent
    linear-gradient(152deg, #febd8e 0%, #eb64d3 49%, #7d8dff 100%) 0% 0%
    no-repeat padding-box; */
  /* color: linear-gradient(152deg, #febd8e 0%, #eb64d3 49%, #7d8dff 100%); */
  background: -webkit-linear-gradient(
    152deg,
    #febd8e 0%,
    #eb64d3 49%,
    #7d8dff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.div-container-title {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.span-disver-pilsar {
  font: normal normal 300 16px/27px Poppins;
  letter-spacing: 0px;
  color: #1f1922;
  opacity: 1;
}
.titleGetADemo {
  font: normal normal 600 42px/54px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.main-container {
  /* background-color: red; */
  min-height: calc(100vh - 72px);
  display: flex;
  /* flex-wrap: wrap; */
}
.main-container-left {
  /* background-color: red; */
  width: clamp(300px, 50%, 1000000px);
  /* height: 100%; */
  padding: 46px 81px;
  display: flex;
  flex-direction: column;
  /* background: transparent url("../assets/img/bg_principal.svg") 0% 0% no-repeat
    padding-box; */
  /* align-items: center; */
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.main-container-rigth {
  padding: 46px 55px;
  width: clamp(300px, 50%, 1000000px);
  min-height: 100%;
  background: transparent
    linear-gradient(152deg, #febd8e 0%, #eb64d3 49%, #7d8dff 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  flex: 1;
}
.app-bar-container {
  /* background-color: red; */
  width: 100%;
  height: 72px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000001a;
  /* margin-bottom: 14px; */
}
/* .text-title {
  font-size: 35px;
  font-weight: bold;
} */
.card-radius {
  border-radius: 90px !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
</style>
